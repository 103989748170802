import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Button, Form } from "react-bootstrap";
import logo from "../assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import apiService from "../api/apiService";

const Login = () => {
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const isUserLoggedIn = localStorage.getItem("isLogin") === "true";
    if (isUserLoggedIn) {
      navigate("/product-scanning");
    }
  }, [navigate]);

  // LOGIN SCHEMA UPDATED
  const loginschema = yup.object().shape({
    username: yup.string().required("Please enter userid"),
    password: yup.string().required("Please enter password"),
  });
  

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(loginschema),
  });

  const onSubmit = async (data) => {
    try {
      setIsButtonDisabled(true);

      const formData = new FormData();
      formData.append("username", data.username);
      formData.append("password", data.password);

      const response = await apiService.post(
        "https://asti.prisminfoways.com/api/login",
        formData,
        {},
        "multipart/form-data"
      );

      if (response.status === 1) {
        toast.success(response.message);

        localStorage.setItem("customerId", response.data.id);
        localStorage.setItem("customerName", response.data.name);
        localStorage.setItem("isLogin", true);
        navigate("/product-scanning");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("Failed to login. Please try again.");
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 2000);
    }
  };

  return (
    <div className="login-area">
      <div className="login-main">
        <img src={logo} alt="Logo" className="login-logo" />
        <div className="login-container">
          <h2 className="login-title">Log In</h2>
          <Form className="login-form" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="name">
              <Form.Label>User ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="UserID"
                name="username"
                {...register("username")}
                isInvalid={!!errors.username}
              />
              <Form.Control.Feedback type="invalid">
                {errors.username?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                {...register("password")}
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              className="btn btn--form"
              type="submit"
              disabled={isButtonDisabled || Object.keys(errors).length > 0}
            >
              Log in
            </Button>
          </Form>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Login;
