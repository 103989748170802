import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import logo from "../assets/img/logo.png";
import logout from "../assets/img/log-out.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../api/apiService";

const ProductScanning = () => {
  const navigate = useNavigate();

  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedPart, setSelectedPart] = useState("");
  const [binQty, setBinQty] = useState(0);
  const [binNumber, setBinNumber] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [isBlocked, setIsBlocked] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [reportList, setReportList] = useState(null);
  const [error, setError] = useState(""); //error filed to show error

  useEffect(() => {
    const isUserLoggedIn = localStorage.getItem("isLogin") !== "true";
    if (isUserLoggedIn) {
      navigate("/login");
    }

    getCustomerList();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("customerId");
    localStorage.removeItem("customerName");
    localStorage.removeItem("isLogin");
    navigate("/login");
  };

  const getCustomerList = async () => {
    try {
      const response = await apiService.get(
        "https://asti.prisminfoways.com/api/customer_list"
      );
      setCustomerList(response.data);
    } catch (error) {
      console.error("Error fetching customer list:", error);
    }
  };

  // for synchronous update
  useEffect(() => {
    if (selectedCustomer !== "" || selectedPart !== "") {
      getReport();

    }
  }, [selectedCustomer, selectedPart]);

  // synchronous part no on reload
  useEffect(() => {
    if (customerList.length > 0) {
      const initialCustomer = customerList[0].name;
      setSelectedCustomer(initialCustomer);
      handleCustomerChange({ target: { value: initialCustomer } });
    }
  }, [customerList]);

  const handleCustomerChange = (event) => {
    setisLoading(false);
    setApiCalled(false);

    const selectedCustomerName = event.target.value;
    setSelectedCustomer(selectedCustomerName);

    const selectedCustomerData = customerList.find(
      (customer) => customer.name === selectedCustomerName
    );

    if (selectedCustomerData) {
      setSelectedPart(selectedCustomerData.part_no[0]);
      setBinQty(selectedCustomerData.bin_qty[0]);
    }
  };

  // ... existing code ...

  const handlePartChange = (event) => {
    setisLoading(false);
    setApiCalled(false);

    const selectedPart = event.target.value;
    setSelectedPart(selectedPart);

    const selectedCustomerData = customerList.find(
      (customer) => customer.name === selectedCustomer
    );

    if (selectedCustomerData) {
      const partIndex = selectedCustomerData.part_no.indexOf(selectedPart);
      setBinQty(selectedCustomerData.bin_qty[partIndex]);
    }
  };

  const handleBinNumberChange = (event) => {
    setisLoading(false);
    setApiCalled(false);
    setBinNumber(event.target.value);
  };

  const AccountBloked = (title, message) => {
    Swal.fire({
      icon: "error",
      title: title,
      text: message,
    });
    handleLogout();
  };

  const getReport = async () => {
    try {
      const response = await apiService.post(
        "https://asti.prisminfoways.com/api/serial_report",
        {
          part_no: selectedPart,
        }
      );
      setReportList(response);
      setisLoading(false);
      setSerialNumber("");
      setBinNumber("");
    } catch (error) {
      console.error("Error on Load Report", error);
    }
  };

  //updated this function working logic
  const handleSerialNumberChange = (event) => {
    const enteredSerialNumber = event.target.value;

    setSerialNumber(enteredSerialNumber);

    const partPrefix = selectedPart;
    const enteredPartPrefix = enteredSerialNumber.slice(
      5,
      5 + partPrefix.length
    );

    if (enteredPartPrefix !== partPrefix) {
      setError(
        "Serial number must be the same as the part number (characters 6 onwards)"
      );
      return;
    } else {
      setError("");
    }

    if (enteredSerialNumber.length !== 27) {
      setError("Serial number should be exactly 27 characters");
      return;
    } else {
      setError("");
    }

    if (!isBlocked && setSerialNumber) {
      setisLoading(true);
      console.log("in")
      handleAddSerialNumber();
    }
  };

  // handle error logs and main working logic of the main api call
  const handleAddSerialNumber = async () => {
    getReport();
    if (apiCalled) {
      return;
    }
  
    setApiCalled(true);
  
    const custtomerID = localStorage.getItem("customerId");
    if (reportList && reportList.data) {
      if (reportList.data.length < binQty) {
        try {
          const response = await apiService.post(
            "https://asti.prisminfoways.com/api/serial_no_add",
            {
              customer: selectedCustomer,
              part_no: selectedPart,
              bin_no: binNumber,
              serial_number: serialNumber,
              user_id: custtomerID,
            }
          );
  
          if (response.status === 2) {
            handleLogout();
  
            toast.error(response.message);
            return;
          } else {
            toast.success("Added Successfully");
          }
          getReport();
          setApiCalled(false);
        } catch (error) {
          console.error("Error adding serial number:", error);
          toast.error(error);
          setApiCalled(false);
        }
      } else {
        toast.error("Bin Is Full");
        setApiCalled(false);
      }
    } else {
      toast.error("Please try again");
      setApiCalled(false);
    }
  };

  const showSuccessAlert = () => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Operation completed successfully!",
    });
  };

  const showErrorAlert = (message) => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Oops! Something went wrong.",
    });
  };

  return (
    <>
      <div className="nav-area">
        <img src={logo} className="logo-nav" alt="logo" />
        <button className="logout-btn" onClick={handleLogout}>
          <img src={logout} alt="logout" />
        </button>
      </div>
      <div className="main-wrapper">
        <div className="container">
          <div className="row g-5">
            <div className="col-md-5">
              <h4 className="mb-4">LIST ALL PRODUCTS</h4>
              <div className="form-group-area">
                <form>
                  <div className="mb-3">
                    <label htmlFor="name" className="mb-2">
                      Customer{" "}
                    </label>
                    <select
                      id="customer"
                      className="form-control form-select"
                      onChange={handleCustomerChange}
                      value={selectedCustomer}
                    >
                      {customerList.map((customer) => (
                        <option key={customer.name} value={customer.name}>
                          {customer.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name" className="mb-2">
                      Part No.{" "}
                    </label>
                    <select
                      id="part"
                      className="form-control form-select"
                      onChange={handlePartChange}
                      value={selectedPart}
                    >
                      {customerList
                        .find((customer) => customer.name === selectedCustomer)
                        ?.part_no.map((part) => (
                          <option key={part} value={part}>
                            {part}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="binno" className="mb-2">
                      Bin Barcode
                    </label>
                    <input
                      id="binno"
                      className="form-control"
                      type="text"
                      placeholder="Bin No."
                      name="binno"
                      value={binNumber}
                      onChange={handleBinNumberChange}
                      required
                    />
                  </div>
                  <div className="mb-1">
                    <label htmlFor="serialno" className="mb-2">
                      Serial No.
                    </label>
                    <input
                      id="serialno"
                      className="form-control"
                      type="text"
                      placeholder="Serial No."
                      name="serialno"
                      value={serialNumber}
                      onChange={handleSerialNumberChange}
                      required
                      disabled={
                        !(selectedCustomer && selectedPart && binNumber)
                      }
                      maxLength={27}
                    />
                  </div>
                  {error && (
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {error}
                    </div>
                  )}
                </form>
              </div>
            </div>
            <div className="col-md-7">
              <div className="mb-4 d-flex flex-column flex-md-row justify-content-end">
                <div className="badge-box mb-3 mb-md-0 me-md-3">
                  BIN QTY. || {binQty}
                </div>
                <div className="badge-box">
                  Scan QTY. || {reportList?.data?.length || 0}
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered table-hover table-striped-columns product-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Serial No.</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportList?.data?.map((item, index) => (
                      <tr key={"report" + index}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.serial_number}</td>
                        <td>
                          {" "}
                          <i
                            className={`${
                              item.status !== "0"
                                ? "fa-solid fa-check true-box"
                                : "fa-solid  fa-xmark false-box"
                            }`}
                          ></i>{" "}
                          {item.status !== "0" ? "OK" : "Failed"}{" "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductScanning;
