import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import React from 'react'
import Login from "../componants/Login";
import ProductScanning from "../componants/ProductScanning";

function MyRoutes() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/product-scanning" element={<ProductScanning />} />
                <Route path="/" element={<Navigate to="/login" />} />
            </Routes>
        </Router>
    )
}

export default MyRoutes;