import React, { useEffect } from 'react';
import MyRoutes from "./routes";
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Aos from 'aos';
import 'aos/dist/aos.css';


function App() {
    const helmetContext = {};
    useEffect(() => {
        Aos.init({
          // Global options go here
        });
      }, []);
    return (    
        <>
            <HelmetProvider context={helmetContext}>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                    toastClassName={'Toastify__toast-theme--light'}
                />
                <MyRoutes />
            </HelmetProvider>
        </>
    );
}

export default App;
